import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";
import SEO from "components/seo";
import Layout from "components/layout";


export default () => (
    <StaticQuery
        query={graphql`
            query {
                allAboutpageJson {
                    edges {
                        node {
                            content
                            image {
                                childImageSharp {
                                    fluid(maxWidth:600) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            title
                            internal {
                                contentDigest
                            }
                        }
                    }
                }
            }
        `}
        render={data => (
            <Layout>
                <SEO
                    pageData={{
                        title: `About the Sydney Football Stadium`,
                        description: `A history and background information about the Sydney Football Stadium.`
                    }}
                />
                <div className="container-fluid">
                    <h1>About the SFS</h1>

                    {data.allAboutpageJson.edges.map((edge,index) => {
                        return (
                            <div className="md:flex mb-12" key={edge.node.internal.contentDigest}>
                                <div className="md:w-1/2 md:pr-6">
                                    <h2 dangerouslySetInnerHTML={{ __html: edge.node.title }} />
                                    <p dangerouslySetInnerHTML={{ __html: edge.node.content }} />
                                </div>
                                <Img className="md:w-1/2" fluid={edge.node.image.childImageSharp.fluid} alt={edge.node.title } />
                            </div>
                        )
                    })}
                    
                </div>
            </Layout>
        )}
    />
)
